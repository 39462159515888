<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view></router-view>
    </transition>
    <div v-if="LiveChat">
      <LiveChatWidget
          :license="license"
          visibility="hidden"  />
    </div>
  </div>
</template>
<script>
import {configList, customization, getKefuList} from '@/api/user'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import {LiveChatWidget} from "@livechat/widget-vue/v2";
import {Dialog} from "vant";

export default {
  components: {
    LiveChatWidget,
    Dialog,
  },
  data() {
    return {
      transitionName: '',
      hideKefu: false,
      LiveChat: false,
      license: '',
    };
  },
  watch: {
    $route(to, from) {
      if (to.meta.index < from.meta.index) {
        this.transitionName = 'slide-right'
      } else if (to.meta.index > from.meta.index) {
        this.transitionName = 'slide-left'
      }
      let name = this.$route.name
      if (name == 'download') {
        this.hideKefu = true
      }
    }
  },
  computed: {
    kefuLink() {
      return this.$store.state.lineKf
    }
  },
  mounted() {
    this.init()
    this.kfInit()
    let locale = localStorage.getItem('locale') || 'en'
    this.i18n.locale = locale
    //左右滑
    this.resetMover()
  },
  methods: {
    changeFavicon(newFaviconUrl) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = newFaviconUrl;

      document.getElementsByTagName('head')[0].appendChild(link);
    },
    init() {
      customization().then(res => {
        let data = res.data
        if(data["chile_bank"] !== undefined) {
          this.$store.state.chile_bank = data["chile_bank"]
        }
        // chile_exchange_rate
        if(data["chile_exchange_rate"] !== undefined) {
          this.$store.state.chile_exchange_rate = parseFloat(data["chile_exchange_rate"])
        }
        // asset_positive
        if(typeof data["asset_positive"] == "boolean") {
          this.$store.state.asset_positive = data["asset_positive"]
        }
        if(data["data"] !== undefined) {
          if (data["data"]["register_phone"]!== undefined) {
            this.$store.state.register_phone = data["data"]["register_phone"]
          }
          if (data["data"]["show_diff"]!== undefined) {
            this.$store.state.show_diff = data["data"]["show_diff"]
          }
        }

      })
      configList().then(res => {
        let data = res.data.list
        // 分享链接
        this.$store.state.appPromotion = data.filter(v => {
          return v.name == 'app_promotion'
        })[0].value
        let tmp = data.filter(v => {
          return v.name == 'show_freeze'
        })
        this.$store.state.show_freeze = tmp.length > 0 ? parseInt(tmp[0].value,10) : 0

        tmp = data.filter(v => {
          return v.name == 'withdraw_bind_address'
        })
        this.$store.state.withdraw_bind_address = tmp.length > 0 ? parseInt(tmp[0].value,10) : 0
        // withdraw_bind_bank
        tmp = data.filter(v => {
          return v.name == 'withdraw_bind_bank'
        })
        this.$store.state.withdraw_bind_bank = tmp.length > 0 ? parseInt(tmp[0].value,10) : 0
        //show_collection
        tmp = data.filter(v => {
          return v.name == 'show_collection'
        })
        this.$store.state.show_collection = tmp.length > 0 ? parseInt(tmp[0].value,10) : 0
        // logo
        tmp =  data.filter(v => {
          return v.name == 'logo'
        })
        this.$store.state.logo = tmp.length > 0 ? "/upload/"+tmp[0].value : ''
        this.changeFavicon(this.$store.state.logo)
        // share_title
        try {
          this.$store.state.share_title = parseInt(data.filter(v => {
            return v.name == 'share_title'
          })[0].value,10)
        } catch (e) {

        }
        // exchange_rate
        try {
          this.$store.state.exchange_rate = parseFloat(data.filter(v => {
            return v.name == 'exchange_rate'
          })[0].value).toFixed(2)
        } catch (e) {

        }
        // // 最低提现金额
        // this.$store.state.appWithdrawMin = data.filter(v => {
        //   return v.name == 'app_withdraw_min'
        // })[0].value
        // // 提现手续费
        // this.$store.state.appWithdrawFee = data.filter(v => {
        //   return v.name == 'app_withdraw_fee'
        // })[0].value
        // // 运营时间1
        // this.$store.state.openingHours1 = data.filter(v => {
        //   return v.name == 'opening_hours1'
        // })[0].value
        // // 运营时间2
        // this.$store.state.openingHours2 = data.filter(v => {
        //   return v.name == 'opening_hours2'
        // })[0].value

        // 提现时间
        // this.$store.state.WithdrawHours1 = data.filter(v => {
        //   return v.name == 'withdraw_hours1'
        // })[0].value
        // // 提现时间2
        // this.$store.state.WithdrawHours2 = data.filter(v => {
        //   return v.name == 'withdraw_hours2'
        // })[0].value
        // // 运营时间2
        // this.$store.state.openingHours2 = data.filter(v => {
        //   return v.name == 'opening_hours2'
        // })[0].value

        // 语言
        this.$store.state.language = data.filter(v => {
          return v.name == 'language'
        })[0].value
        // 游客访问 visitor_service
        tmp = data.filter(v => {
          return v.name == 'visitor_service'
        })
        this.$store.state.visitor_service = tmp.length > 0 ? parseInt(tmp[0].value,10) : 0

        try {
          // pop_window
          this.$store.state.pop_window = data.filter(v => {
            return v.name == 'pop_window'
          })[0].value
        } catch (e) {

        }
        //下载地址
        this.$store.state.iosDownloadUrl = data.filter(v => {
          return v.name == 'download_ios'
        })[0].value
        this.$store.state.androidDownloadUrl = data.filter(v => {
          return v.name == 'download_android'
        })[0].value

        console.log(111, this.$store.state.iosDownloadUrl)


        let appName = data.filter(v => {
          return v.name == 'app_name'
        })[0].value
        this.$store.state.appName = appName
        document.title = appName
        let pop_window = sessionStorage.getItem('pop_window1')

        if(this.$store.state.pop_window === "true" && pop_window !== "true") {
          // appName
          let text = this.i18n.t("index.desc")
          let title = this.i18n.t("index.desc_title")
          let ensure = this.i18n.t("common.ensure")
          text = text.replaceAll("$1", this.$store.state.appName);
          text = text.replaceAll("$2", "\n");
          Dialog.alert({
            message: text,
            title:title,
            messageAlign: 'left',
            confirmButtonText: ensure,
          }).then(() => {
            // 记录sessionStorage
            sessionStorage.setItem('pop_window1', 'true')
            // on close
          });
        }
      })
    },
    toKefu() {
      window.location.href = this.kefuLink
    },
    kfInit(){
      getKefuList().then(res => {
        let data  = res.data.Items
        if(data.length === 0) {
          return
        }
        if(data[0].Type == 'chatra') {
          // 预加载
          (function(d, w, c,token) {
            w.ChatraID = token;
            var s = d.createElement('script');
            w[c] = w[c] || function() {
              (w[c].q = w[c].q || []).push(arguments);
            };
            s.async = true;
            s.src = 'https://call.chatra.io/chatra.js';
            if (d.head) d.head.appendChild(s);
            window.ChatraSetup = {
              startHidden: true
            };
          })(document, window, 'Chatra',data.Token);
          return;
        }
        // 检查连接包含的字符串qiabot.html
        if(data[0].Link.indexOf('qiabot.html') > -1) {
          let src = "https://a.wauda.net/starter.js"
          this.insertScript(src)
          return;
        }

        if(data[0].Link.indexOf('lc.chat') > -1) {
          const regex = /\/(\d+)\//;
          const match = data[0].Link.match(regex);
          if (match) {
            this.LiveChat = true
            this.license = match[1]
          }
        }
        if(data[0].Link.indexOf('kefu-global') > -1) {
          const regex = /\/(\d+)\//;
          const match = data[0].Link.match(regex);
          let src = data[0].Link + "/packs/js/sdk.js"
          this.insertScript(src)
          this.chatwoot(data[0].Link)
        }
        if(data[0].Link.indexOf('tawk.to') > -1) {
          window.localStorage.setItem('propertyId', data[0].Token)
          window.localStorage.setItem('widgetId', data[0].IdentifierHash)
          this.$tawk()
          this.$tawkMessenger.$on('load', () => {
            console.log("加载完成")
            this.$store.state.tawkLoaded = true
            // place your code here
          });
        }
      })
    },
    insertTawk(src){
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src=src;
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
    },
    chatwoot(Link){
      let that = this;
      if (Link.length === 0) {
        return;
      }
      let len = Link.indexOf("/widget");
      if (len === -1) {
        return;
      }
      var BASE_URL=Link.substr(0,len);
      let tokenlen = Link.indexOf("token=");
      let token = Link.substr(tokenlen+6);
      window.chatwootSDK.run({
        websiteToken: token,
        baseUrl: BASE_URL
      });
      that.inter = setInterval(function(){
        if(document.getElementsByClassName("woot-widget-bubble")[0] !== undefined) {
          that.show = true;
          clearInterval(that.inter);
          window.$chatwoot.toggleBubbleVisibility('hide');
        }
      },50);
    },
    insertScript(src) {
      // 创建一个新的 <script> 元素
      const script = document.createElement('script');

      // 设置 <script> 的 src 属性为你的文件路径
      script.src = src;

      // 使用 onload 事件处理程序来确保脚本加载完成后执行操作
      script.onload = () => {
        console.log('脚本已加载并可用。');

        // 在这里你可以执行脚本加载后的操作
      };

      // 将 <script> 元素添加到页面的 <head> 中，或者你希望的位置
      document.head.appendChild(script);
    },
    resetMover() {
      let startX = 0
      let startY = 0
      window.document.addEventListener('touchstart', (e) => {
        startX = e.targetTouches[0].pageX
        startY = e.targetTouches[0].pageY
      })
      window.document.addEventListener('touchmove', (e) => {
        let moveX = e.targetTouches[0].pageX
        let moveY = e.targetTouches[0].pageY
        if (Math.abs(moveX - startX) > Math.abs(moveY - startY)) {//左右滑
          e.preventDefault()
        }
      }, { passive: false })
      //
    },
    greetingHidden(event){
      console.log("greetingHidden", event)
    },
    greetingDisplayed(event){
      console.log("greetingDisplayed", event)
    },
    availabilityChanged(event){
      console.log("availabilityChanged", event)
    },
    visibilityChanged(event){
      console.log("visibilityChanged", event)
    },
    customerStatusChanged(event){
      console.log("customerStatusChanged", event)
      if (event.status === "browsing") {
        this.maximized = "hidden"
      }
    },
    handleNewEvent(event) {
      console.log('LiveChatWidget.onNewEvent', event)
      if(event.visibility === "hidden") {
        window.location.href = "/"
      }
      // minimized
      if(event.visibility === "minimized") {
        window.location.href = "/"
      }
    },
  }
}
</script>


<style lang="less">
/* 页面切换动画效果 */
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 200ms;
  position: absolute;
  // backface-visibility: hidden;
  // perspective: 1000px;
}
.slide-right-enter,
.slide-right-enter-active {
  animation: bounce-left-in 200ms;
}
.slide-right-leave-active {
  animation: bounce-left-out 200ms;
}
.slide-left-enter,
.slide-left-enter-active {
  animation: bounce-right-in 200ms;
}
.slide-left-leave-active {
  animation: bounce-right-out 200ms;
}

@keyframes bounce-right-in {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce-right-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes bounce-left-in {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce-left-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
</style>