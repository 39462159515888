import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      index: 1
    },
    component: () => import('@/views/login/login')
  },
  {
    path: '/kf2',
    name: 'kf2',
    meta: {
      index: 1
    },
    component: () => import('@/views/kefu/kf2'),
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      index: 2
    },
    component: () => import('@/views/login/register')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index'),
    children: [
      {
        path: '/index',
        name: 'index',
        meta: {
          index: 1
        },
        component: () => import('@/views/index/index'),
      },
      {
        path: '/order',
        name: 'order',
        meta: {
          index: 1
        },
        component: () => import('@/views/order/order'),
      },
      {
        path: '/task',
        name: 'task',
        meta: {
          index: 1
        },
        component: () => import('@/views/task/task'),
      },

      {
        path: '/kefu',
        name: 'kefu',
        meta: {
          index: 1
        },
        component: () => import('@/views/kefu/kefu'),
      },

      {
        path: '/mine',
        name: 'mine',
        meta: {
          index: 1
        },
        component: () => import('@/views/mine/mine'),
      },
    ]
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/recharge'),
  },
  {
    path: '/bankRecharge',
    name: 'bankRecharge',
    meta: {
      index: 3
    },
    component: () => import('@/views/mine/bankRecharge'),
  },
  {
    path: '/usdtRecharge',
    name: 'usdtRecharge',
    meta: {
      index: 3
    },
    component: () => import('@/views/mine/usdtRecharge'),
  },
  {
    path: '/rechargeDetail',
    name: 'rechargeDetail',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/rechargeDetail'),
  },
  //   rechargeDetail
  {
    path: '/withdraw',
    name: 'withdraw',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/withdraw'),
  },
  {
    path: '/bankWithdraw',
    name: 'bankWithdraw',
    meta: {
      index: 3
    },
    component: () => import('@/views/mine/bankWithdraw'),
  },
  {
    path: '/usdtWithdraw',
    name: 'usdtWithdraw',
    meta: {
      index: 3
    },
    component: () => import('@/views/mine/usdtWithdraw'),
  },
  {
    path: '/bankList',
    name: 'bankList',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/bankList'),
  },
  {
    path: '/usdtList',
    name: 'usdtList',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/usdtList'),
  },
  {
    path: '/usdtDetail',
    name: 'usdtDetail',
    meta: {
      index: 3
    },
    component: () => import('@/views/mine/usdtDetail'),
  },
  {
    path: '/bankDetail',
    name: 'bankDetail',
    meta: {
      index: 3
    },
    component: () => import('@/views/mine/bankDetail'),
  },
  {
    path: '/address',
    name: 'address',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/address'),
  },
  {
    path: '/addAddress',
    name: 'addAddress',
    meta: {
      index: 3
    },
    component: () => import('@/views/mine/addAddress'),
  },
  {
    path: '/bind',
    name: 'bind',
    meta: {
      index: 4
    },
    component: () => import('@/views/mine/bind'),
  },
  {
    path: '/bindCard',
    name: 'bindCard',
    meta: {
      index: 4
    },
    component: () => import('@/views/mine/bindCard'),
  },
  {
    path: '/share',
    name: 'share',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/share'),
  },
  {
    path: '/record',
    name: 'record',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/record'),
  },
  {
    path: '/team',
    name: 'team',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/team'),
  },
  {
    path: '/company',
    name: 'company',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/company'),
  },
  {
    path: '/header',
    name: 'header',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/header'),
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      index: 3
    },
    component: () => import('@/views/download/download'),
  },
  {
    path: '/to-login',
    name: 'to-login',
    meta: {
      index: 2
    },
    component: () => import('@/views/login/tologin'),
  },

  {
    path: '*',
    redirect: '/index'
  }
]

const router = new VueRouter({
  routes
})

const whiteArr = ['register', 'login', 'index', 'lang','download',"kf2","kefu","to-login"]
const grayPage = ['bankRecharge', 'recharge', 'usdtRecharge', 'bankWithdraw', 'usdtWithdraw', 'address', 'bind', 'record', 'team', 'bankList', 'bankDetail', 'usdtList', 'usdtDetail']
router.beforeEach((to, from, next) => {
  if (grayPage.indexOf(to.name) > -1) {
    document.body.style.background  = '#f9f9f9'
  } else {
    document.body.style.background  = '#FFFFFF'
  }


  let token = getToken()
  if (token) {
    next()
  } else {
    if (whiteArr.indexOf(to.name) > -1) {
      next()
    } else {
      // next('login')
      next()
    }
  }
})
// 添加全局错误处理
router.onError(error => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    window.location.reload();
  } else {
    console.error(error);
  }
});
router.afterEach(() => {
  window.scrollTo({top: 0})
})

export default router
