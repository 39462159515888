import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseUrl: '/upload/',
    appName: '',
    avatar: '1',
    appPromotion: '',
    show_freeze: '1',
    show_collection: 0,
    exchange_rate: 0,
    withdraw_bind_address: '0',
    withdraw_bind_bank: '0',
    language: '',
    pop_window: '',
    tawkLoaded:false,
    visitor_service:1,
    logo: '',
    share_title: 0,
    iosDownloadUrl: '',
    androidDownloadUrl: '',
    chile_bank: 'false',
    chile_exchange_rate: 0,
    asset_positive: false,
  //   注册需要填写手机号
    register_phone: false,
    show_diff: false,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
